import { AxiosResponse } from "axios";
import { ApiCMSInstance } from "data/ApiCMS";

export const MUTATION_CREATE_MEDIA_FILE = `mutation CreateMediaFile($file: Upload!) { createMediaFile(file: $file) { name } }`;

export type ResponseCreateMediaFile = {
  createMediaFile: {
    name: string;
  };
};

export async function ApiCMSCreateMidiaFile(
  file: any
): Promise<AxiosResponse<{ data?: ResponseCreateMediaFile; errors?: Error[] }>> {
  const data = new FormData();
  const query = MUTATION_CREATE_MEDIA_FILE;
  data.append(
    "operations",
    JSON.stringify({ query, operationName: "CreateMediaFile", variables: { file: null } })
  );
  data.append("map", JSON.stringify({ "0": ["variables.file"] }));
  data.append("0", file);
  return ApiCMSInstance.post<{
    data?: ResponseCreateMediaFile;
    errors?: Error[];
  }>("", data, { headers: { "Content-Type": "multipart/form-data" }, timeout: 300000 });
}
