import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";
import { PaginationInfo } from "components/CRPComponents/Tables/GenericTable/types";

export const GET_ALL_QUERY = print(gql`
  query TemplatesEmail {
    TemplatesEmail {
      items {
        id
        name
        fileName
        sendCopyTo
        subjectDefault
        jsonDataExample
        createdAt
        updatedAt
      }
      paginationInfo {
        page
        perPage
        hasMore
        totalPages
        totalData
        nextPage {
          page
          perPage
        }
      }
    }
  }
`);

export interface GetAllItem {
  id: 1;
  name: string;
  fileName: string;
  sendCopyTo: string[];
  subjectDefault: string;
  jsonDataExample: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface GetAllGql {
  TemplatesEmail: {
    items: GetAllItem[];
    paginationInfo: PaginationInfo;
  };
}
