import { gql } from "@apollo/client";
import { print } from "@apollo/client/utilities";

export const CREATE_QUERY = print(gql`
  mutation CreateTemplateEmail($data: CreateTemplateEmailInput!) {
    createTemplateEmail(data: $data) {
      id
      name
      fileName
      sendCopyTo
      subjectDefault
      jsonDataExample
      createdAt
      updatedAt
    }
  }
`);

export interface CreateGql {
  createTemplateEmail: {
    id: 1;
    name: string;
    fileName: string;
    sendCopyTo: string[];
    subjectDefault: string;
    jsonDataExample: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
