import GenericTable from "../Tables/GenericTable";
import MDButton from "components/MDButton";
import DefaultCell from "../Tables/components/DefaultCell";
import { GET_ALL_QUERY, GetAllGql, GetAllItem } from "./queries/getAll";
import ModalCustom from "../ModalCustom";
import { ApiAuthWithSetData } from "data/ApiAuth";

const btnStyle = {
  border: "1px solid #7b809a",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
};

const btnStyleRemove = {
  ...btnStyle,
  border: "1px solid #F44335",
  color: "#F44335",
};

export default function ModalUserCopySelect({ ids, handleOpen, onChange, open }: any) {
  console.log({ ids });
  return (
    <ModalCustom
      onClose={handleOpen}
      open={open}
      sx={{
        "& .head": {
          justifyContent: "center",
          width: "100%",
        },
      }}
    >
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 8,
          search: "",
          status: null,
        }}
        path={`config/templateEmailAll`}
        query={GET_ALL_QUERY}
        formatRows={(data) => {
          console.log("data findmany users", data);
          return data.findManyUsers.map((item) => ({ id: item.id, name: item.userName }));
        }}
        formatPagination={(data) => [] as any}
        ApiSetData={ApiAuthWithSetData}
        columnsTable={[
          {
            Header: "Ação",
            accessor: "id",
            Cell: ({ value, cell }: any) => (
              <MDButton
                sx={ids.includes(value) ? btnStyleRemove : btnStyle}
                onClick={() => onChange(cell.row.original as GetAllItem)}
              >
                {ids.includes(value) ? "Remover" : "Adicionar"}
              </MDButton>
            ),
          },
          {
            Header: "Nome",
            accessor: "name",
            Cell: ({ value }: any) => <DefaultCell value={value} />,
          },
        ]}
      />
    </ModalCustom>
  );
}
