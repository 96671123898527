import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import GenericTable from "components/CRPComponents/Tables/GenericTable";
import LinkCell from "components/CRPComponents/Tables/components/LinkCell";
import { GET_ALL_QUERY, GetAllGql } from "./queries/getAll";
import { ApiMailerWithSetData } from "data/queries/ApiMailer";

function List() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <GenericTable<GetAllGql>
        initialVariables={{
          page: 1,
          perPage: 10,
          search: null,
          active: null,
        }}
        path="/config/templateEmailAll"
        query={GET_ALL_QUERY}
        formatRows={(data) => data.TemplatesEmail.items}
        formatPagination={(data) => data.TemplatesEmail.paginationInfo}
        title="NOVA TAG"
        ApiSetData={ApiMailerWithSetData}
        columnsTable={[
          {
            Header: "name",
            accessor: "name",
            Cell: ({ value }: any) => (
              <LinkCell href={`/config/templateEmail/${value}`} value={value} />
            ),
          },
          // {
          //   Header: "Nome",
          //   accessor: "name",
          //   Cell: ({ value }: any) => <DefaultCell value={value} />,
          // },
        ]}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default List;
